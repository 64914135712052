let resumeData = {
  imagebaseurl: "https://rbhatia46.github.io/",
  name: "Xavier Mas Leszkiewicz",
  role: "Technology Leader, Entrepreneur, Software Engineer and Music Producer",
  linkedinId: "xavier-mas-leszkiewicz",
  skypeid: "xmasle.telecos",
  roleDescription:
    "\nA visionary technology leader with a passion for innovation and continuous learning",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/xavier-mas-leszkiewicz/",
      className: "fa fa-linkedin",
    },
    {
      name: "github",
      url: "https://github.com/masosky",
      className: "fa fa-github",
    },
    {
      name: "twitter",
      url: "https://twitter.com/xmasle",
      className: "fa fa-twitter",
    },
    {
      name: "youtube",
      url: "https://www.youtube.com/channel/UCl6lQmCSE8LMItNFyOEw6Hw",
      className: "fa fa-youtube",
    },
    {
      name: "instagram",
      url: "https://instagram.com/xavyxmusic",
      className: "fa fa-instagram",
    }
  ],
  aboutme: `Highly strategic technology leader with a proven track record of building innovative solutions. Driven by a methodical approach to problem-solving and a commitment to excellence. Entrepreneur, Software Engineer, and Music Producer with over a decade of experience transforming business challenges into technological opportunities.`,
  address: "Barcelona",
  website: "https://blueberrybytes.com",
  education: [
    {
      UniversityName:
        "UPC School. School of Professionals & Executive Development",
      specialization: "Postgraduate in Quantum Engineering",
      MonthOfPassing: "May",
      YearOfPassing: "2023",
      // Achievements: "Some Achievements"
    },
    {
      UniversityName:
        "UPC School. School of Professionals & Executive Development",
      specialization: "Big Data Management & Analytics",
      MonthOfPassing: "July",
      YearOfPassing: "2016",
      // Achievements: "Some Achievements"
    },
    {
      UniversityName: "UPC. Universitat Politècnica de Catalunya",
      specialization: "Bachelor degree in Audiovisual Systems",
      MonthOfPassing: "March",
      YearOfPassing: "2015",
      // Achievements: "Some Achievements"
    },
    {
      UniversityName: "Technische Universität Berlin",
      specialization: "Bachelor Thesis",
      MonthOfPassing: "February",
      YearOfPassing: "2015",
      Achievements:
        "Object Tracking with multiple cameras using real-world coordinates",
    },
  ],
  work: [
    {
      CompanyName: "Blueberry Bytes",
      specialization: "CTO & Co-Founder",
      MonthOfJoining: "October",
      YearOfJoining: "2024",
      MonthOfLeaving: "",
      YearOfLeaving: "",
      Achievements: `Leading the technological vision and strategy for a cutting-edge digital solutions company. Spearheading the development of scalable mobile applications, responsive web platforms, and robust backend architectures. Overseeing a talented team of engineers while fostering a culture of innovation and technical excellence. Directly involved in client consultations to translate business requirements into tailored technology solutions that drive growth and digital transformation.`,
    },
    {
      CompanyName: "Lumenalta",
      specialization: "Senior Full-Stack Engineer",
      MonthOfLeaving: "September",
      YearOfLeaving: "2024",
      Achievements: `Delivered high-impact technical solutions as a consultant for enterprise clients. Architected and implemented comprehensive software solutions utilizing React, Ionic, NodeJS, SQL, AWS, and GCP. Collaborated with cross-functional teams to translate complex business requirements into scalable and maintainable code. Provided technical leadership and mentorship to junior developers while ensuring adherence to best practices and coding standards.`,
    },
    {
      CompanyName: "Elliptic",
      specialization: "Senior Full-Stack Engineer",
      MonthOfLeaving: "January",
      YearOfLeaving: "2023",
      Achievements: `Engineered sophisticated UI and backend systems for blockchain analytics in the cryptocurrency sector. Developed an intuitive application enabling users to navigate and analyze transactions across multiple blockchains including Bitcoin and Ethereum. Implemented dynamic data visualization components to present complex blockchain data in an accessible format. Collaborated with security and compliance teams to ensure adherence to financial regulations while maintaining optimal performance.`,
    },
    {
      CompanyName: "Full on Net (Telefonica)",
      specialization: "Senior Software Engineer",
      MonthOfLeaving: "May",
      YearOfLeaving: "2022",
      Achievements: `Led the strategic consolidation of LATAM and Spain infrastructure for all IPTV events, resulting in significant operational efficiencies and cost reductions. Architected and implemented a unified system using Python, Java, MongoDB, Kafka, Kafka Streams, Celery, and Behave. Designed and deployed scalable microservices architecture to handle high-volume streaming events across multiple regions. Collaborated with international teams to ensure seamless integration while maintaining service reliability during the transition.`,
    },
    {
      CompanyName: "Relevanzz",
      specialization: "Co-Founder and CTO",
      MonthOfLeaving: "July",
      YearOfLeaving: "2020",
      Achievements: `Established and led a technology startup as CTO and Co-Founder, gaining comprehensive insight into all aspects of business operations. Directed strategic initiatives across technology, marketing, sales, and business development to drive company growth. Spearheaded the end-to-end product development lifecycle, from conceptualization to market launch. Orchestrated all technical aspects including product design (UI/UX), infrastructure architecture, and software development. Built and managed cross-functional teams while establishing agile development methodologies to ensure efficient product delivery.`,
    },
    {
      CompanyName: "eDreams ODIGEO",
      specialization: "Software Engineer - Machine Learning POD",
      MonthOfLeaving: "July",
      YearOfLeaving: "2019",
      Achievements: `Architected and implemented robust backend infrastructure to support advanced machine learning initiatives for Europe's largest online travel company. Collaborated closely with data scientists to optimize and deploy ML models into production environments. Designed scalable systems capable of processing and analyzing large volumes of travel data to enhance customer experience. Utilized Java, Python, Docker, Maven, Kafka, Kubernetes, Kibana, and Elastic Search to build high-performance, resilient systems that powered data-driven decision making across the organization.`,
    },
    {
      CompanyName: "eDreams ODIGEO",
      specialization: "Java Developer - BI Revamp",
      MonthOfLeaving: "February",
      YearOfLeaving: "2018",
      Achievements: `Led the comprehensive revamp of business intelligence systems, utilizing Java, NoSQL, SQL, and Maven to process and analyze large-scale datasets. Assumed end-to-end ownership of the project lifecycle from initial architecture design through production deployment. Engineered efficient ETL processes to transform and consolidate data from multiple sources into actionable business insights. Implemented performance optimizations that reduced data processing time by 40% while improving data accuracy and reliability.`,
    },
    {
      CompanyName: "SIA Group. Sistemas Informáticos Abiertos",
      specialization: "Java Developer and IT Security",
      MonthOfLeaving: "January",
      YearOfLeaving: "2017",
      Achievements: `Managed critical identity and security infrastructure in a high-stakes government environment, overseeing protection for more than 250,000 user identities. Orchestrated complex application migrations while enhancing security protocols and system performance. Developed and optimized scripts using Oracle Identity Manager to streamline identity management processes. Implemented robust security solutions utilizing diverse technologies including Active Directory, Oracle database, Visual Basic, Siteminder (SSO), Spring, and Struts frameworks to ensure compliance with stringent government security standards.`,
    },
  ],
  skillsDescription: "My most outstanding skills",
  skills: [
    {
      skillname: "Technology Leadership",
    },
    {
      skillname: "Software Architecture",
    },
    {
      skillname: "Team Building & Mentorship",
    },
    {
      skillname: "Strategic Problem Solving",
    },
    {
      skillname: "Business & Technical Integration",
    },
  ],
  portfolio: [
    {
      name: "project1",
      description: "mobileapp",
      imgurl: "images/portfolio/phone.jpg",
    },
    {
      name: "project2",
      description: "mobileapp",
      imgurl: "images/portfolio/project.jpg",
    },
    {
      name: "project3",
      description: "mobileapp",
      imgurl: "images/portfolio/project2.png",
    },
    {
      name: "project4",
      description: "mobileapp",
      imgurl: "images/portfolio/phone.jpg",
    },
  ],
  testimonials: [
    {
      description: "This is a sample testimonial",
      name: "Some technical guy",
    },
    {
      description: "This is a sample testimonial",
      name: "Some technical guy",
    },
  ],
  aboutXavyx: "I am an amateur music producer who enjoys creating Deep House tracks. I am super excited to collaborate with different producers to learn and improve my skills.",
  youTubeChannel: "https://www.youtube.com/channel/UCl6lQmCSE8LMItNFyOEw6Hw",
  soundCloud: "https://soundcloud.com/xavyx-music/",
  xavyxInstagram: "https://instagram.com/xavyxmusic",
  xavyxSpotify: "https://open.spotify.com/artist/6XQEl2BobkH08FyQzQruqP?si=6nQR0-4eRaesGyZp6XV9nQ&dl_branch=1",
};

export default resumeData;
